(function ($) {
  Drupal.behaviors.basicCarouselFormatterV1 = {
    attach: function (context) {
      var self = this;
      var $formatter = $('.js-basic-carousel-formatter-v1', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $formatter.each(function () {
        var $this = $(this);
        var $carousel = $this.find('.js-basic-carousel-formatter__carousel');
        var useResponsive = false;
        var autoplay = $carousel.data('slides-autoplay');
        var fade = $carousel.data('fade');
        var slidesToShow = parseInt($carousel.data('slides-show'));
        var slidesToScroll = parseInt($carousel.data('slides-scroll'));
        var slidesToShowMobile = parseInt($carousel.data('slides-show-mobile'));
        var slidesToScrollmobile = parseInt($carousel.data('slides-scroll-mobile'));
        var slidesArrowLeft = $carousel.data('arrow-left');
        var slidesArrowRight = $carousel.data('arrow-right');
        var arrowTopPosition = $carousel.data('arrow-top-position');
        var disableDragging = $carousel.data('disable-dragging-pc') ? true : false;
        var disableDraggingMobile = $carousel.data('disable-dragging-mobile') ? true : false;
        var disableInfinite = $carousel.data('disable-infinite-pc') ? true : false;
        var disableInfiniteMobile = $carousel.data('disable-infinite-mobile') ? true : false;
        var initialSlide = $carousel.data('initial-slide-pc');
        var initialSlideMobile = $carousel.data('initial-slide-mobile');
        // Responsive dots / arrows
        var pcDotsArrowsArgs = self.dotsAndArrows($carousel.data('dots-arrows-pc'));
        var mobileDotsArrowsArgs = self.dotsAndArrows($carousel.data('dots-arrows-mobile'));
        var speed = parseInt($carousel.data('speed'));
        var peekingPC = false;
        var peekingMob = false;
        var centerPadding = '30px';
        var offset;
        var slickPrev = '.slick-prev';
        var slickNext = '.slick-next';
        var slickScrollRtl = false;
        var dirSwap = $('html[dir="rtl"]').length;
        var $youTubeVideo = $carousel.find('.js-ytPlayer__icon, .js-ytPlayer__copy');
        var settingsPlain, settingsResponsive;

        // fade settings
        if (typeof fade === 'undefined') {
          fade = false;
        } else {
          fade = true;
        }

        runChecks();
        reverseScroll();

        // plain with defaults
        settingsPlain = {
          infinite: !disableInfinite,
          autoplay: autoplay,
          fade: fade,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          arrows: pcDotsArrowsArgs.arrows,
          rows: 1,
          speed: speed,
          rtl: slickScrollRtl,
          centerMode: peekingPC,
          centerPadding: centerPadding,
          initialSlide: initialSlide,
          draggable: !disableDragging
        };
        // case for custom slides count and dots for mobile
        settingsResponsive = {
          infinite: !disableInfinite,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          fade: fade,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          rows: 1,
          speed: speed,
          centerMode: peekingPC,
          centerPadding: centerPadding,
          rtl: slickScrollRtl,
          initialSlide: initialSlide,
          draggable: !disableDragging,

          responsive: [
            {
              breakpoint: 1024,
              settings: {
                infinite: !disableInfiniteMobile,
                centerMode: peekingMob,
                centerPadding: centerPadding,
                dots: mobileDotsArrowsArgs.dots,
                arrows: mobileDotsArrowsArgs.arrows,
                slidesToShow: slidesToShowMobile,
                slidesToScroll: slidesToScrollmobile,
                initialSlide: initialSlideMobile,
                draggable: !disableDraggingMobile
              }
            }
          ]
        };

        checkResponsive();
        customArrows();

        // Handle RTL values.
        function reverseScroll() {
          if (dirSwap) {
            slickScrollRtl = true;
            $carousel.attr('dir', 'rtl');
          }
        }

        function checkCenterPadding() {
          if ($this.data('peaking-center-padding')) {
            centerPadding = $this.data('peaking-center-padding') + 'px';
          }
          if ($this.data('peaking-center-padding-percent')) {
            centerPadding = $this.data('peaking-center-padding-percent') + '%';
          }
          if ($this.data('peeking_offset')) {
            offset = $this.data('peeking_offset') + 'px';
          }
        }

        function checkAutoPlay() {
          if (typeof autoplay === 'undefined') {
            autoplay = false;
          } else {
            autoplay = true;
          }
        }

        function runChecks() {
          // peeking settings
          if ($this.hasClass('js-basic-carousel-formatter--pc_peeking')) {
            peekingPC = true;
          }
          if ($this.hasClass('js-basic-carousel-formatter--mob-peeking')) {
            peekingMob = true;
          }
          checkCenterPadding();
          checkAutoPlay();
        }

        function checkResponsive() {
          // slide counts
          if (slidesToShowMobile > 2) {
            useResponsive = true;
          }
          $carousel.not('.slick-initialized').on('init', function () {
            if ($this.data('peaking-center-padding')) {
              if ($this.hasClass('js-mob-peeking-align-left')) {
                var leftOffset = offset ? offset : centerPadding;

                $this.find('.slick-list').css('left', '-' + leftOffset);
              }
            }
          });
          $carousel.on('afterChange', function () {
            var dataId = $('.slick-current').attr('data-slick-index');

            $.event.trigger({
              type: 'basic_carousel_formatter:current_slide',
              payload: dataId
            });
          });

          $carousel.on('beforeChange', function (event, slick, currentSlide) {
            if (slick['$nextArrow']) {
              currentSlide === slick.slideCount - 2
                ? slick['$nextArrow'].addClass('hide')
                : slick['$nextArrow'].removeClass('hide');
            }
            if (slick['$prevArrow']) {
              currentSlide === 1
                ? slick['$prevArrow'].addClass('hide')
                : slick['$prevArrow'].removeClass('hide');
            }
          });

          if (useResponsive === 'true') {
            $carousel.not('.slick-initialized').slick(settingsPlain);
          } else {
            $carousel.not('.slick-initialized').slick(settingsResponsive);
          }

          if ($youTubeVideo && $youTubeVideo.length > 1) {
            $.event.trigger({
              type: 'yt_dynamic_player:apply_listeners',
              payload: $youTubeVideo
            });
          }
        }

        // Set the custom image sources.
        function customArrows() {
          checkCustomArrows(slickPrev, slidesArrowLeft);
          checkCustomArrows(slickNext, slidesArrowRight);
        }
        // Check the custom arrows.
        function checkCustomArrows(dir, dataAttr) {
          if (!($this.find(dir).css('background-image') === 'url(' + dataAttr + ')')) {
            $this.find(dir).css('background-image', 'url(' + dataAttr + ')');
            if (dataAttr) {
              $this.find(dir).addClass('no-before');
            }
            if (arrowTopPosition) {
              $this.find(dir).css('top', arrowTopPosition);
            }
          }
        }

        // Deeplinking to specific slide.
        function moveToSlide(event) {
          var index;

          if (event.payload !== undefined) {
            index = event.payload;
          }
          if ($carousel.length > 0 && index !== undefined) {
            $carousel[0].slick.slickGoTo(parseInt(index));
          }
        }

        // Handle custom image source when the window is resized,
        // as slick re-initialises for its responsive settings.
        $(window).on('resize', _.debounce(customArrows, 100));
        $(document).on('basic_carousel_formatter:move_to_slide', moveToSlide);
      });
    },
    dotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    }
  };
})(jQuery);
